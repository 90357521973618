import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import AttendeeInfo from "./App";
import Registro from "./Registro";
import LandingPage from "./LandingPage";
import ConfirmationPage from "./ConfirmationPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <Router>
      <Routes>
        <Route path="/s/:token" element={<AttendeeInfo/>} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/registro" element={<Registro />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />
      </Routes>
    </Router>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
