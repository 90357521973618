import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

// Import your assets
import logo_usuarios from "./assets/Logo_Usuarios.png";
import logo_pnsv from "./assets/logo_30_scania.png";
import logo_25 from "./assets/logo_30_scania.png";
import logo_heroes from "./assets/pnsv_PantallasDeAcceso_Heroes.jpg";
import img_cross from "./assets/pnsv_PantallasDeAcceso_DENEGADO.png";
import img_exist_logo from "./assets/pnsv_PantallasDeAcceso_OK.png";
import backgroundImage from "./assets/back.jpg";

const API_URL = "https://celebracion30aniversario.contactoscaniamexico.com/api";

function AttendeeInfo() {
  const { token } = useParams();
  const [attendee, setAttendee] = useState(null);
  const [error, setError] = useState(false);
  const [escaneos, setEscaneos] = useState([]);

  const [loggedIn, setLoggedIn] = useState(() => {
    return localStorage.getItem("loggedIn") === "true";
  });
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  // Responsive styling based on window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (loggedIn) {
      const fetchAttendee = async () => {
        try {
          const { data } = await axios.post(`${API_URL}/escanear`, {
            codigo_qr: token,
          });
          if (data.status === "no valido") {
            setError(true);
          } else {
            setAttendee(data.asistente);
            setEscaneos(data.escaneos);
            if (data.asistente.bloqueado) {
              setError("bloqueado");
            }
          }
        } catch (error) {
          setError(true);
        }
      };
      
      fetchAttendee();
    }
  }, [token, loggedIn]);

  useEffect(() => {
    // Handle window resize for responsive styles
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/verificar_codigo_acceso`, {
        code,
      });
      if (response.data.success) {
        localStorage.setItem("loggedIn", "true");
        setLoggedIn(true);
      } else {
        setCodeError("Código incorrecto");
      }
    } catch (error) {
      setCodeError("Error al verificar el código");
    }
  };

  // Styles
  const isMobile = windowWidth <= 760;

  const styles = {
    container: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      textAlign: "center",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    },
    headerLogoUsuarios: {
      width: isMobile ? "60%" : "50%",
      maxWidth: "400px",
      height: "auto",
      marginTop: "20px",
    },
    headerLogoPnsv: {
      width: isMobile ? "85%" : "50%",
      maxWidth: "400px",
      height: "auto",
    },
    imgCross: {
      width: isMobile ? "25%" : "10%",
      maxWidth: "300px",
      height: "auto",
      marginTop: "20px",
    },
    imgExistLogo: {
      width: isMobile ? "25%" : "10%",
      maxWidth: "300px",
      height: "auto",
      marginTop: "10px",
    },
    imgLogo25: {
      width: isMobile ? "60%" : "40%",
      maxWidth: "350px",
      height: "auto",
      marginTop: "50px",
    },
    imgLogoHeroes: {
      width: isMobile ? "100%" : "60%",
      height: "auto",
      marginTop: "20px",
    },
    headerText: {
      fontSize: isMobile ? "24px" : "32px",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#ffffff",
      marginTop: "20px",
    },
    infoText1: {
      fontSize: isMobile ? "38px" : "48px",
      fontWeight: "bolder",
      marginBottom: "10px",
      color: "#ffffff",
      margin: "10px",
      lineHeight: "1",
    },
    infoText2: {
      fontSize: isMobile ? "24px" : "32px",
      fontWeight: "400",
      marginBottom: "10px",
      color: "#ffffff",
      margin: "10px",
      lineHeight: "1",
    },
    infoText3: {
      fontSize: isMobile ? "28px" : "38px",
      marginBottom: "40px",
      color: "#ffffff",
      margin: "10px",
      lineHeight: "1",
    },
    attendeeInfoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    input: {
      fontSize: "2vh",
      padding: "1vh",
      margin: "1vh",
    },
    button: {
      fontSize: "2vh",
      padding: "1vh 2vh",
    },
  };

  // Render Header with Logos
  const renderHeader = () => (
    <div style={{ textAlign: "center" }}>
      <img src={logo_pnsv} alt="Logo 30" style={styles.headerLogoPnsv} />
    </div>
  );

  if (!loggedIn) {
    return (
      <div style={styles.container}>
        {renderHeader()}
        <h1 style={styles.headerText}>Ingrese el código de acceso</h1>
        <form onSubmit={handleCodeSubmit}>
          <input
            type="password"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            style={styles.input}
          />
          <br />
          <button type="submit" style={styles.button}>
            Ingresar
          </button>
        </form>
        {codeError && <p style={{ color: "red" }}>{codeError}</p>}
      </div>
    );
  }

  if (error) {
    let errorMessage = "EL ASISTENTE NO EXISTE";
    if (error === "bloqueado") {
      errorMessage = "ACCESO DENEGADO";
    }
    return (
      <div style={styles.container}>
        {renderHeader()}
        {error === "bloqueado" && attendee && (
          <div style={styles.attendeeInfoContainer}>
            <p style={styles.infoText1}>
              {`${attendee.nombres} ${attendee.apellido_paterno} ok ${attendee.apellido_materno}`.toUpperCase()}
            </p>
            <p style={styles.infoText2}>
              ASOCIACIÓN NACIONAL DE TRANSPORTE PRIVADO A.C.
            </p>
          </div>
        )}
        <img src={img_cross} alt="Acceso Denegado" style={styles.imgCross} />
        <h1 style={styles.headerText}>{errorMessage}</h1>

      </div>
    );
  }

  if (!attendee) {
    return <h1>Cargando...</h1>;
  }

  // Check if attendee has previous scans
  const hasPreviousScans = escaneos && escaneos.length > 1; // More than one because the current scan is included
  const firstScanDateTime = hasPreviousScans
    ? new Date(escaneos[escaneos.length - 1].fecha_escaneo) // Get the first scan (oldest)
    : null;

  const formatDateTime = (date) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "UTC",
    };
    return new Intl.DateTimeFormat("es-MX", options)
      .format(date)
      .replace(",", " -");
  };

  if (hasPreviousScans) {
    // Attendee has been scanned before
    return (
      <div style={styles.container}>
        {renderHeader()}
        <div style={styles.attendeeInfoContainer}>
          <p style={styles.infoText1}>
            {`${attendee.nombre}`.toUpperCase()}
          </p>

        </div>
        <img
          src={img_cross}
          alt="Código Ya Utilizado"
          style={styles.imgCross}
        />
        <h1 style={styles.headerText}>
          Este código ya fue utilizado
          <br />
          {formatDateTime(firstScanDateTime)}
        </h1>

      </div>
    );
  }

  // First scan - show success screen
  return (
    <div style={styles.container}>
      {renderHeader()}
      <div style={styles.attendeeInfoContainer}>
        <p style={styles.infoText1}>
          {`${attendee.nombre}`.toUpperCase()}
        </p>
      </div>
      <img
        src={img_exist_logo}
        alt="Acceso Permitido"
        style={styles.imgExistLogo}
      />
      <p style={styles.infoText3}>BIENVENID@</p>

    </div>
  );
}

export default AttendeeInfo;
