import React from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "./assets/back.jpg";
import logo from "./assets/logo_30_scania.png";

function LandingPage() {
  const navigate = useNavigate();

  const styles = {
    container: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "white",
      flexDirection: "column",
      padding: "20px",
    },
    logo: {
      width: "80%",
      maxWidth: "12em",
      marginBottom: "30px",
    },
    headline: {
      fontSize: "1.2em",
      marginBottom: "20px",
    },
    subheadline: {
      fontSize: "1.2em",
      maxWidth: "420px",
      lineHeight: "1.5",
    },
    date: {
      fontFamily: "ScaniaOfficeBold",
      fontSize: "24px",
      fontWeight: "bold",
      marginTop: "20px",
      textTransform: "uppercase",
    },
    date2: {
      fontFamily: "ScaniaOfficeBold",
      fontSize: "19px",
      fontWeight: "bold",

      textTransform: "uppercase",
    },
    limite:{
      fontSize: "12px",
      marginTop: "50px",
    },
    limitespan:{
      fontSize: "12px",
      color: "#fccf8c"
    },
    time: {
      fontSize: "1.6em",
      fontWeight: "bold",
      margin: "0 0",
    },
    button: {
      backgroundColor: "#fccf8c",
      color: "black",
      border: "none",
      padding: "15px 30px",
      borderRadius: "25px",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "bold",
      textTransform: "uppercase",
      marginTop: "20px",
    },
    buttonHover: {
      backgroundColor: "#FFA000",
    },
  };

  const handleNavigate = () => {
    navigate("/registro");
  };

  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo Scania" style={styles.logo} />
      <div style={styles.headline}>Estimados colaboradores:</div>
      <div style={styles.subheadline}>
        Los invitamos a festejar juntos este cierre de año.
      </div>
      <div style={styles.subheadline}>La cita es el:</div>
      <div style={styles.date}>
        20 de diciembre de 2024
        <br />
        de 2 pm a 10 pm
      </div>
      <div>en</div>
      <div style={styles.date2}>LA Casona de los 5 Patios</div>
      Santiago de Querétaro
      <button onClick={handleNavigate} style={styles.button}>
        Confirmar Asistencia
      </button>

      <div style={styles.limite}>FECHA LIMITE:<span style={styles.limitespan}>16 DE DICIEMBRE</span></div>
    </div>
  );
}

export default LandingPage;
