import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import backgroundImage from "./assets/back.jpg";
import bienvenido from "./assets/bienvenido.png";
import logo from "./assets/logo_30_scania.png";

const API_URL = "https://celebracion30aniversario.contactoscaniamexico.com/api";

function Registro() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    asistira: null,
    nombre: "",
    email: "",
    puesto: "",
    direccion: "",
    restricciones_alimentarias: "",
  });
  const [direcciones] = useState([
    "Dirección General",
    "Administración y Finanzas",
    "People Experience & Culture",
    "Customer Experience & Services",
    "People Transport Solutions",
    "Cargo Transport Solutions",
    "Minería y Aplicaciones Especiales",
    "Scania Finance",
    "Retail",
  ]);
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setEmailError(""); // Reset email error
    }
  };

  const handleToggle = (value) => {
    setFormData({ ...formData, asistira: value });
  };

  const verifyEmail = async () => {
    try {
      const { data } = await axios.get(`${API_URL}/check-email`, {
        params: { email: formData.email },
      });
      if (data.registered) {
        setEmailError("Este correo electrónico ya está registrado.");
      } else {
        setEmailError(""); // Clear email error if email is not registered
      }
    } catch (error) {
      console.error("Error verifying email:", error);
      setEmailError("Hubo un error al verificar el correo electrónico.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError) {
      setErrorMessage("Corrige los errores antes de enviar el formulario.");
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/asistentes`, formData);

      setSuccessMessage("¡Gracias por confirmar tu asistencia!");
      navigate("/confirmation", { state: { email: formData.email, qrcode: response?.data?.codigo_qr } });
    } catch (error) {
      console.error("Error submitting form:", error);
      setSuccessMessage("");
      setErrorMessage(
        "Hubo un error al registrar. Por favor, inténtalo de nuevo."
      );
    }
  };

  const styles = {
    container: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    formContainer: {
      width: "90%",
      maxWidth: "400px",
      backgroundColor: "transparent",
      padding: "30px",
      borderRadius: "15px",
      color: "white",
      textAlign: "center",
    },
    logo: {
      width: "80%",
      maxWidth: "12em",
      marginBottom: "20px",
    },
    logobienvenido: {
      width: "80%",
      maxWidth: "22em",
    },
    toggleContainer: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
      gap: "10px",
    },
    toggleButton: {
      width: "80px",
      padding: "10px",
      borderRadius: "25px",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "bold",
      border: "none",
      color: "white",
      backgroundColor: "#ccc",
      transition: "background-color 0.3s",
    },
    toggleButtonActive: {
      backgroundColor: "#4CAF50", // Green for "Sí"
    },
    toggleButtonInactive: {
      backgroundColor: "#F44336", // Red for "No"
    },
    input: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "8px",
      border: "none",
      fontSize: "16px",
      boxSizing: "border-box",
    },
    select: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "8px",
      border: "none",
      fontSize: "16px",
    },
    button: {
      backgroundColor: "#fccf8c",
      color: "black",
      border: "none",
      padding: "12px 20px",
      borderRadius: "25px",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "20px",
      textTransform: "uppercase",
    },
    errorText: {
      color: "red",
      fontSize: "14px",
    },
    successText: {
      color: "green",
      fontSize: "14px",
    },
    subtitle: {
      fontSize: "16px",
      margin: "10px 0",
      marginBottom: "2em",
      color: "#fccf8c",
    },
    headline: {
      fontSize: "15px",
      margin: "10px 0",
      color: "#FFFFFF",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <img src={logo} alt="Logo Scania" style={styles.logo} />
        <img src={bienvenido} alt="Bienvenido" style={styles.logobienvenido} />
        <div style={styles.headline}>
          Confirma tu asistencia a la Cena de Fin de Año y 30 Aniversario de
          SCANIA MÉXICO, que se llevará acabo el
        </div>
        <div style={styles.subtitle}>
          20 de diciembre de 2024 - 14h en la <h2 style={{fontFamily: "ScaniaOfficeBold",}}>"CASONA DE LOS 5 PATIOS"</h2>{" "}
          Santiago de Querétaro
        </div>

        <p
          style={{
            fontWeight: "bold",
            fontSize: "32px",
            marginBottom: "10px",
            textTransform: "uppercase",
            fontFamily: "ScaniaOfficeBold",
          }}
        >
          Confirmar Asistencia
        </p>
        <div style={styles.toggleContainer}>
          <button
            type="button"
            style={{
              ...styles.toggleButton,
              ...(formData.asistira === true ? styles.toggleButtonActive : {}),
            }}
            onClick={() => handleToggle(true)}
          >
            Sí
          </button>
          <button
            type="button"
            style={{
              ...styles.toggleButton,
              ...(formData.asistira === false
                ? styles.toggleButtonInactive
                : {}),
            }}
            onClick={() => handleToggle(false)}
          >
            No
          </button>
        </div>

        {formData.asistira === true && (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="nombre"
              placeholder="Nombre Completo"
              value={formData.nombre}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <input
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              value={formData.email}
              onChange={handleChange}
              onBlur={verifyEmail}
              required
              style={styles.input}
            />
            {emailError && <p style={styles.errorText}>{emailError}</p>}
            <input
              type="text"
              name="puesto"
              placeholder="Puesto"
              value={formData.puesto}
              onChange={handleChange}
              required
              style={styles.input}
            />
            <select
              name="direccion"
              value={formData.direccion}
              onChange={handleChange}
              required
              style={styles.select}
            >
              <option value="">Área</option>
              {direcciones.map((direccion, index) => (
                <option key={index} value={direccion}>
                  {direccion}
                </option>
              ))}
            </select>
            <select
              name="restricciones_alimentarias"
              value={formData.restricciones_alimentarias}
              onChange={handleChange}
              required
              style={styles.select}
            >
              <option value="">Restricciones Alimenticias</option>
              <option value="Menú vegano">Menú vegano</option>
              <option value="Menú vegetariano">Menú vegetariano</option>
              <option value="Sin restricciones">Sin restricciones</option>
            </select>
            <button type="submit" style={styles.button}>
              Aceptar
            </button>
          </form>
        )}

        {formData.asistira === false && (
          <div style={{ marginTop: "40px", marginBottom: "10px" }}>
            <h2 style={{ textTransform: "uppercase" }}>
              ¡Gracias por tu respuesta!
            </h2>
            <h2 style={{ color: "#fccf8c", textTransform: "uppercase" }}>
              Feliz navidad y próspero año nuevo 2025
            </h2>
          </div>
        )}
        {successMessage && <p style={styles.successText}>{successMessage}</p>}
        {errorMessage && <p style={styles.errorText}>{errorMessage}</p>}
      </div>
    </div>
  );
}

export default Registro;
