import React from "react";
import { useLocation } from "react-router-dom";
import backgroundImage from "./assets/back.jpg";
import logo from "./assets/logo_30_scania.png";

function ConfirmationPage() {
  const location = useLocation();
  const email = location.state?.email || "Correo no especificado"; // Fallback if email is missing
  const qrcode = location.state?.qrcode || undefined;

  const qrUrl = qrcode
    ? `https://celebracion30aniversario.contactoscaniamexico.com/api/descargar-boleto?codigo_qr=${qrcode}`
    : null;

  const qrUrlsingle = qrcode
    ? `https://celebracion30aniversario.contactoscaniamexico.com/api/get-qr?code=${qrcode}`
    : null;

    const handleDownload = (event) => {
      // Prevent any default behavior
      event.preventDefault();
      event.stopPropagation();
    
      if (qrUrl) {
        const link = document.createElement("a");
        link.href = qrUrl;
        //link.target = "_blank"; // Open in a new tab
        link.rel = "noopener noreferrer"; // Security best practice
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };
    

  const styles = {
    container: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    content: {
      width: "90%",
      maxWidth: "420px",
      backgroundColor: "transparent",
      color: "white",
      padding: "30px",
      borderRadius: "15px",
      textAlign: "center",
    },
    logo: {
      width: "80%",
      maxWidth: "12em",
      marginBottom: "20px",
    },
    qrimage: {
      width: "100%",
      maxWidth: "300px",
      marginBottom: "20px",
    },
    button: {
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "bold",
      color: "white",
      backgroundColor: "#fccf8c",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    heading: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#fccf8c",
      marginBottom: "20px",
    },
    message: {
      fontSize: "16px",
      lineHeight: "1.5",
      marginBottom: "20px",
      color: "#FFFFFF",
    },
    highlight: {
      color: "#fccf8c",
      fontWeight: "bold",
    },
    footer: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "white",
      marginTop: "30px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <img src={logo} alt="Logo Scania" style={styles.logo} />
        {qrUrl && (
          <>
            <img src={qrUrlsingle} alt="QR" style={styles.qrimage} />
            <button style={styles.button} onClick={(e) => handleDownload(e)}>
              Descargar QR
            </button>
          </>
        )}
        <h1 style={styles.heading}>GRACIAS POR TU CONFIRMACIÓN</h1>
        <p style={styles.message}>
          En breve recibirás un correo electrónico de confirmación en la
          siguiente dirección: <span style={styles.highlight}>{email}</span>
        </p>
        <p style={styles.message}>
          Si no encuentras el mail de confirmación en tu bandeja de entrada, es
          posible que esté en la carpeta de SPAM/Correo no deseado.
        </p>
        <h2 style={styles.footer}>¡TE ESPERAMOS!</h2>
      </div>
    </div>
  );
}

export default ConfirmationPage;
